body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color : "#000000"
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.App-content-2{
  padding:0px !important;
}
.ag-header-cell-label .ag-header-cell-text {
  white-space: normal !important;
  display: inline-block;
  text-align: center;
  flex: 1 1;
}
.ag-theme-balham .ag-header-group-cell-label {
  text-align: center;
  justify-content: center;
  
}
.ag-theme-balham .ag-header-cell-label .ag-header-cell-text{
  color: black !important;
  font-weight: normal;
}
/* .ag-fresh .ag-header-cell-label .ag-header-cell-text
{
display: inline-block;
text-align: center;
} */


/* .ag-cell{
  white-space: normal !important;
  height: auto !important;
} */

@media (min-width: 600px){
  .MuiCardContent-root-140 {
    padding:10px !important;
  }
} 
.example-full-width-floating-row {
  background-color: #2aabd2;
  border: 2px solid darkblue;
  /* we want the border to be kept within the row height */
  box-sizing: border-box;
  /* get the row to fill the available height */
  height: 100%;
  /* grid sets white-space to one line, need to reset for wrapping the text*/
  white-space: normal;
}
.example-full-width-row {
  background-color: #bbffbb;
  border: 2px solid #22ff22;
  /* we want the border to be kept within the row height */
  box-sizing: border-box;
  /* get the row to fill the available height */
  height: 100%;
  /* grid sets white-space to one line, need to reset for wrapping the text*/
  white-space: normal;
}
/* to help highlight the example, shade blue to top and bottom floating*/
.ag-floating-top .ag-row {
  background-color: lightblue !important;
}
.ag-floating-bottom .ag-row {
  background-color: lightblue !important;
}
.ag-theme-balham .status-ontime {
  background-color: rgb(241, 229, 219) !important;
}
.ag-theme-balham .status-delay {
  background-color: rgb(238, 123, 23) !important;
}
.ag-theme-balham .status-critical-delay {
  background-color: rgb(230, 13, 13) !important;
}

.ag-overlay-loading-center {
  height: 100%;
  left: 0;

  pointer-events: none;

  position: absolute;
  top: 28px;
  width: 100%;
}

.something2 {
  left: 13px;
  margin: 13;
}

.something {
  height: 100%;
  margin: 0 auto;
  left: -10px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.arrow-right {
  background-color: #444;
  height: 10px;
  left: -5px;
  position: absolute;
  top: -5px;
  width: 10px;
  -webkit-transform: rotate(-45deg);
}

.blink{
  animation:blinking 1s infinite;
}
@keyframes blinking{
  0%{background-color: transparent;}
  10%{}
  49%{}
  50%{}
  99%{}
  100%{}
}

.ag-header-cell-text {
  flex: 1 1;
}
.text-center {
  text-align: center;
}

.location-checkbox-header {
  text-align: center;
  padding-left: 0px;
  padding-right: 0px;
}
.custom-tooltip{
  padding : 5px;
  background-color: #FFF;
  fill-opacity: 0.8;
  border: #CCC 1px solid;
  box-shadow: #444 2px;
}
.ag-theme-balham .ag-row-selected{
  border-bottom-color: #DADADA !important;
  background-color: #DADADA !important;
}
/* .ag-theme-balham .multiline .ag-header-cell-label .ag-header-cell-text {
  text-overflow: clip;
  overflow: visible;
  white-space: normal;
  height: auto;
  line-height: 1.5em;
} */

/* .blink-yellow{
  animation:blinkingOrange 1s infinite;
}
@keyframes blinkingOrange{
  0%{     background-color: transparent;    }
  10%{     background-color: transparent;    }
  49%{    background-color: #EDD502; }
  50%{    background-color: #EDD502; }
  99%{    background-color: #EDD502;  }
  100%{   background-color: #EDD502;    }
} */


/* .blink {
  animation: blinker 1s step-start infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
} */
.ag-theme-balham .multiline .ag-header-cell-label .ag-header-cell-text {
    text-overflow: clip;
    overflow: visible;
    white-space: normal;
    height: auto;
    line-height: 1.5em;
}
.ag-theme-balham .ag-header-cell, .ag-theme-balham .ag-header-group-cell {
    line-height: 32px;
    padding-left: 6px;
    padding-right: 0px;
}
.ag-theme-balham .multiline .ag-header-cell-label .ag-header-cell-text {
    text-overflow: clip;
    overflow: visible;
    white-space: normal;
    height: auto;
    line-height: 1.5em;
}
.ag-theme-balham .ag-header-cell, .ag-theme-balham .ag-header-group-cell {
    line-height: 32px;
    padding-left: 6px;
    padding-right: 0px;
}
